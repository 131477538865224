.order-wrapper {
    .store-info {
        .store-inner {
            .store-image {
                @media screen and (min-width: 1000px) {
                    height: 200px;
                }
            }
        }
    }
}

.actie-prod-contact-seo {
    @media screen and (min-width: 768px) {
        display: block !important;
    }
}

.actie-prod-container .actie-product .cover-img {
    height: 150px;
    @media screen and (min-width: 767px) and (max-width: 1199px){
        height: 80px;
    }
}

.contact-page-left-side {
    display: table;
}

.row-contact-information {
    display: table-row-group;
}

.row-actie-products {
    display: table-header-group;
}