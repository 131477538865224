.btn-success {
    background-color: $theme_color !important;
    border-color: darken($theme_color, 2.5%) !important;
    &:hover,
    &:active,
    &:focus {
        background-color: darken($theme_color, 10%) !important;
        border-color: darken($theme_color, 12.5%) !important;
    }
}

.btn-primary {
    background-color: $theme_color !important;
    border-color: darken($theme_color, 2.5%) !important;
    &:hover,
    &:active,
    &:focus {
        background-color: darken($theme_color, 10%) !important;
        border-color: darken($theme_color, 12.5%) !important;
    }
}

.postcode-row {
    .postcode-container {
        .postcode-header {
            h2 {
                background-color: $black_2;
            }
        }
        .postcode-body {
            background-color: $black_3;
        }
    }
}

.shop-next {
    display: none;
    @media screen and (max-width: 1299px) {
        display: block;
    }
}

.link-white-normal {
    color: #fff;
    text-decoration: underline;
    &:hover {
        color: #ED1B23;
    }
}

.store-select-wrapper {
    .postcode-row {
        .postcode-container {
            .postcode-header {
                h2 {
                    background-color: #202020;
                }
            }
            .postcode-body {
                background-color: #303030;
            }
        }
    }
    #post_code {
        text-align: center;
    }
    .stores-wrapper {
        max-width: 1150px;
        display: block;
        margin: 0 auto;
        .stores-header {
            background-color: #202020;
            h2 {
                font-size: 1.4em;
                margin: 0;
                padding: 0;
                padding-bottom: 15px;
                font-weight: bolder;
                text-align: center;
                display: block;
                padding-top: 15px;
                color: #fffff4;
            }
        }
        .stores-body {
            background-color: #303030;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .search_input {
                max-width: 100%;
                width: 300px;
                text-align: center;
                display: block;
                margin: 0 auto;
                float: none;
            }
            .search_input::placeholder {
                /*color: #333;*/
            }
            .store-list {
                .store-list-conainer {
                    .store-info {
                        border-radius: 4px;
                        .info-title {
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}