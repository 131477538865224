.header {
    &.add-slider {
        .slick-slider {
        	.slick-nav{
        		color: rgba(180,180,180,0.8);
        	}
            .slider-image {
                height: 250px;
            }
        }
    }
}