.order-wrapper .block-layout .block-prod-container .block-product .cover-img {
    background-size: contain;
    background-repeat: no-repeat;
}

.footer {
    z-index: 5;

    .footer-list {
        margin: 0;
        list-style-type: none;
        margin-top: 25px;
        margin-bottom: 25px;

        li {
            position: relative;
            transition: 100ms all ease-in;

            a {
                color: #fff;
                padding-top: 2.5px;
                padding-bottom: 2.5px;
                margin-bottom: 1px;
                margin-top: 1px;
                display: block;
                color: #999;
            }

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                transition: 150ms all ease-in;
                content: "\f101";
                font-family: FontAwesome;
                display: block;
                margin-left: -1.3em;
                width: 1.3em;
                font-weight: lighter;
                color: #666;
                padding-top: 2.5px;
                padding-bottom: 2.5px;
                padding-right: 10px;
                text-align: right;
            }

            &:hover {
                a {
                    text-decoration: none;
                    color: #ccc;
                }

                &:before {
                    padding-right: 5px;
                    color: $theme_color;
                }
            }
        }
    }

    .app-store-container {
        display: block;
        max-width: 290px;
        margin: 0 auto;

        .app-store-link {
            img {
                max-width: 100%;
                margin: 0 auto;
                display: block;

                &.last-store-app-link {
                    @media screen and (min-width: 768px) {
                        margin-left: -30px;
                    }
                }

                @media screen and (max-width: 767px) {
                    &.last-store-app-link {
                        margin-top: 10px;
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }

    .footer-stores {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        vertical-align: middle;
        /*margin: 0 auto;*/
        /*max-width: 900px;*/
        max-width: 100%;

        li {
            h3 {
                margin: 0px;
                padding: 0px;
                font-size: 11.2px;
            }
            a {
                padding: 2.5px 10px;
                display: block;
                color: #f5f5f5;
                font-size: .8em;
                font-size: 11.2px;

                &:hover {
                    color: $theme_color;
                    text-decoration: none;
                }
            }
        }
    }

    p.credit {
        color: #666;

        strong {
            color: #bbb;
        }

        .smaller-link {
            font-size: 12px;
        }

        a {
            text-decoration: none;

            &:hover {
                color: $theme_color_darken;
                text-decoration: none;

                strong {
                    color: $theme_color_darken;
                }
            }
        }
    }
}

.btn {
    border-radius: 2px;
    transition: 50ms all ease-in;
    border-radius: 0px !important;
}

.container {
    position: relative;
}

.black-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.black-overlay-striped {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('/content/images/pixel.png');
}

@media screen and (min-width: 992px) {
    .navbar-nav {
        margin-left: -25px;
    }
}