.header {
    @media screen and (max-width: 991px) {
        .logo {
            display: block;
            position: relative;
            padding: 8.5px;
            max-height: 50px;
            max-width: 150px;
            position: absolute;
            left: 100px;
            img {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    @media screen and (min-width: 1300px) {
        .navbar .nav-cart .nav-cart-container {
            top: 100px;
        }
    }
    #navbar {
        .nav-cart {
            &.cart-active {
                .cart-href {
                    background-color: $black_1;
                    color: $white_1;
                    .order-info {
                        color: $white_1;
                    }
                    .order-cart {
                        color: $white_1;
                    }
                }
            }
            .nav-cart-container {
                background-color: $black_1;
                color: $white_1;
            }
            @media screen and (max-width: 991px) {
                .cart-href {
                    .order-cart {
                        display: block;
                        position: absolute;
                        width: 55px;
                        height: 100%;
                        line-height: 50px;
                        font-size: 2em;
                        text-align: center;
                        color: #fff;
                        right: 5px;
                    }
                    .order-info {
                        display: flex;
                        float: right;
                        width: 120px;
                        height: 100%;
                        font-size: 15px;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0px 10px;
                        color: #fff;
                        margin-left: 50px;
                        text-align: right;
                        margin-right: 50px;
                    }
                }
            }
        }
    }
}

body {
    .open-side-nav-mobile {
        display: none;
        @media screen and (max-width: 991px) {
            position: absolute;
            display: block;
            margin-left: 8.5px;
            margin-top: 8.5px;
        }
    }
    .header {
        #main_navbar.navbar-default .sub-menu {
            @media screen and (max-width: 991px) {
                border: none;
                position: absolute;
                top: 50px;
                left: 0;
                right: 0;
                width: 100%;
                max-width: none;
                display: block;
                padding-right: 0px;
                background-color: rgba(0, 0, 0, 0.5);
                padding-left: 0px;
                .nav-cart {
                    border: none;
                    margin: 0;
                    padding: 0;
                    .cart-href {
                        width: 100%;
                        border: none;
                        margin-left: 0;
                        margin-right: 0;
                        max-width: none;
                    }
                }
            }
        }
    }
    .header {
        .navbar {
            #CartContainer.nav-cart {
                .nav-cart-container {
                    .list-layout-title {
                        display: none;
                    }
                    @media screen and (min-width: 751px) {
                        width: calc(1465px * 0.25);
                        div.cart-inner {
                            /*overflow-y: inherit;*/
                        }
                        .prod_count {
                            max-width: none;
                            min-width: 40px;
                        }
                    }
                    .list-layout-title {
                        display: block;
                        padding-left: 15px;
                    }
                }
            }
        }
        .price-total-number {
            white-space: nowrap;
        }
    }
}

.ripple-effect {
    background-color: $theme_color !important;
}

@media screen and (min-width: 992px) {
    .nav-categories {
        /*position: relative !important;
        top: 0 !important;*/
    }
}

.helperArrow-container {
    position: fixed;
    display: block;
    background-color: transparent;
    left: 0;
    bottom: 0;
    right: 0;
    height: 60px;
    @media screen and (min-width: 330px) {
        display: none !important;
    }
    .helperArrow {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 5px;
        display: block;
        width: 40px;
        height: 40px;
        /*background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI0MC44MTEgMjQwLjgxMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQwLjgxMSAyNDAuODExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnPgoJPHBhdGggaWQ9IkV4cGFuZF9Nb3JlIiBkPSJNMjIwLjA4OCw1Ny42NjdsLTk5LjY3MSw5OS42OTVMMjAuNzQ2LDU3LjY1NWMtNC43NTItNC43NTItMTIuNDM5LTQuNzUyLTE3LjE5MSwwICAgYy00Ljc0LDQuNzUyLTQuNzQsMTIuNDUxLDAsMTcuMjAzbDEwOC4yNjEsMTA4LjI5N2wwLDBsMCwwYzQuNzQsNC43NTIsMTIuNDM5LDQuNzUyLDE3LjE3OSwwTDIzNy4yNTYsNzQuODU5ICAgYzQuNzQtNC43NTIsNC43NC0xMi40NjMsMC0xNy4yMTVDMjMyLjUyOCw1Mi45MTUsMjI0LjgyOCw1Mi45MTUsMjIwLjA4OCw1Ny42Njd6IiBmaWxsPSIjRDgwMDI3Ii8+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);*/
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiNGRkZGRkYiLz4KICA8L2c+Cjwvc3ZnPgo=);
        background-size: 30px;
        margin: 0 auto;
        background-color: rgba(10, 10, 10, 0.9);
        border-radius: 0;
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation: bounce 2s infinite;
}

body{
    .prod-grid{
        position: inherit;
    }
}

.block-prod-container {
    position: relative;
    .toppings {
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        width: 100%;
        .toppings-inner {
            .topping-flex {
                .Addon {
                    flex-basis: 100%;
                    width: 100%;
                }
            }
        }
    }
    .toppings-inner {
        background-color: rgba(0, 0, 0, 0.6);
        border: 0px solid transparent;
    }
    .adding-prods {
        width: 100%;
        clear: both;
        display: block;
        margin-bottom: 10px;
    }
    .add-prod {
        width: 100%;
        clear: both;
        display: block;
    }
    .add-prod-container {
        background-color: rgba(0, 0, 0, 0.6);
        display: block;
    }
    .has-error .form-control {
        border-color: transparent;
        border-width: 0px;
        border: none;
        box-shadow: 0px 0px 10px 5px rgba(255, 0, 0, 1);
    }
}

.grey-container {
    padding: 20px;
    background-color: #222;
}

#coupon_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        background-color: #111;
        border-left: dotted 2px rgba(255, 255, 255, 0.5);
        display: block;
        padding: 15px 20px;
        margin: 10px;
    }
}

label {
    font-weight: normal;
}

legend {
    margin-top: 10px;
    border-bottom-color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
}