@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');

$body_background: #080001;
$black_1: #101010;
$black_2: #202020;
$black_3: #0A0A0A;
$black_4: #151414;
$white_1: #f7f7f7;
$white_2: #f0f0f0;
$grey: #666666;
$darkgrey: #333;

$theme_color: #ED1B23;
$theme_color_darken: darken($theme_color, 12.5%);

$site_width: 1300px;

* {
    font-family: 'Montserrat', sans-serif;
}